import React from 'react';
import { Icon, IconsStore } from '@vezeeta/web-components';
import Icons from '@vezeeta/web-icons';
import styled from 'styled-components';

export const getFeatureFlags = () => ({});

export const isSupportSingleLanguage = (user, isUserLoaded) => {
  // Get user token
  if (isUserLoaded) {
    // eslint-disable-next-line prefer-destructuring
    const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
    return countriesSupportSingleLanguage.includes(user.CountryId);
  }
};

const iconsStore = new IconsStore(Icons);
const HelpIcon = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.language === 'en' ? 'unset' : 'scale(-1, 1)')};
`;

const sponsoredAdsTutorialComponent = (onClickAction, language) => (
  <HelpIcon
    onClick={() => {
      onClickAction(true);
    }}
    icon={iconsStore.getIcon('help')}
    width={17}
    color="#9C9C9C"
    language={language}
  />
);

export const getbreadCrumbSteps = (localization, language, callBack) => {
  const breadcrumbSteps = [
    [
      {
        name: localization && localization.sponseredAds,
        pathname: '/',
        helpComponent: sponsoredAdsTutorialComponent(callBack, language),
      },
    ],
  ];
  const breadcrumbProps = {
    stepsArray: breadcrumbSteps,
    validationElementName: '#user-form',
  };

  return breadcrumbProps;
};
