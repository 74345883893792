import React from 'react';
import { Flex, EmptyState } from '@vezeeta/web-components';
import PropTypes from 'prop-types';

const NotAutherizedView = ({ ...props }) => {
  const { localization } = props;
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{
        height: '500px',
      }}
    >
      <EmptyState
        image={`${process.env.REACT_APP_CDN_URL}/assets/safe.svg`}
        text={localization.notAutherized}
      />
    </Flex>
  );
};

NotAutherizedView.propTypes = {
  localization: PropTypes.object,
};
export default NotAutherizedView;
